<template>
  <div class="table">
    <div class="container">
      <div class="handle-box">
        <el-date-picker
          style="width: 420px; margin-right: 20px"
          v-model="searchQuery.dateArray"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="onChangeDate"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>

        <el-button icon="el-icon-search" type="primary" @click="fetchData"
          >查询</el-button
        >
        <el-button @click="setSearchQuery">重置</el-button>
        <!-- <el-input
          style="width: 220px; margin-right: 20px"
          placeholder="输入关键字进行过滤"
          v-model="filterText"
        >
        </el-input> -->
      </div>
      <!--数据表格-->
      <el-table
        v-loading="listLoading"
        element-loading-text="正在加载 ..."
        :data="list"
        row-key="id"
        border
        fit
        height="calc( 100vh - 195px ) "
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column prop="name" label="部门名称"></el-table-column>
        <el-table-column
          prop="applicationAmount"
          label="申请金额（元）"
        ></el-table-column>

        <el-table-column
          prop="usedAmount"
          label="消费金额（元）"
        ></el-table-column>
        <el-table-column label="操作" align="left" width="500px">
          <template slot-scope="scope">
            <div class="p-l-50">
              <el-button
                v-if="scope.row.pid !== '0' && scope.row.buttonShow"
                type="warning"
                size="small"
                @click="exportExcelFull(scope.row.id)"
                >导出汇总及明细
              </el-button>
              <el-button
                v-if="scope.row.pid !== '0' && scope.row.buttonShow"
                type="success"
                size="small"
                @click="exportExcel(scope.row.id)"
                >导出汇总账单
              </el-button>
              <el-button
                type="primary"
                size="small"
                v-if="
                  mailList.find((x) => {
                    return x.departId == scope.row.id;
                  })
                "
                @click="sendMail(scope.row)"
              >
                发送邮件
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog
      title="编辑信息"
      width="500px"
      center
      :visible.sync="selectDlgShow"
      :close-on-click-modal="false"
    >
      <div
        v-loading="dlgLoading"
        class="dialog-body"
        element-loading-text="正在保存..."
      >
        <el-form
          ref="form"
          :model="form"
          label-width="80px"
          class="demo-dynamic"
        >
          <el-form-item label="上级部门" prop="pid">
            <el-cascader
              v-model="form.pid"
              :options="options"
              clearable
              :show-all-levels="false"
              :props="{
                value: 'id',
                label: 'name',
                children: 'children',
                emitPath: false,
                checkStrictly: true,
              }"
              placeholder="请选择上级部门"
            ></el-cascader>
            <span class="demo-tip">不填默认为一级部门</span>
          </el-form-item>
          <el-form-item
            label="部门名称"
            prop="name"
            :rules="[
              { required: true, trigger: 'blur', message: '请输入部门名称' },
            ]"
          >
            <el-input v-model="form.name" type="text" clearable />
          </el-form-item>
          <el-form-item label="备注" prop="description">
            <el-input v-model="form.description" type="text" clearable />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="selectDlgShow = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submitForm('form')">
          <!-- <el-button size="small" type="primary" @click="devSave"> -->
          保存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getDepartmentList,
  saveDepartment,
  delDepartment,
  getTreeList,
} from "@/api/admin/department";
import api from "@/api/common";
import { download } from "@/utils/request";
export default {
  name: "Department",
  data() {
    let self = this;

    return {
      options: [],
      list: [],
      mailList: [],
      emptyObj: {},
      searchQuery: {
        dateArray: self.$publicMethods.getHalfMonth(),
      },
      form: {
        id: "",
        name: "",
        pid: "",
        description: "",
      },

      filterText: "",
      listLoading: true,
      dlgLoading: false,
      selectDlgShow: false,
      exportExcelLoading: false,
      sendMailLoading: false,
      multipleSelection: [],
    };
  },
  created() {
    this.fetchData();
    this.getMailList();
    this.getMoney();
  },
  mounted() {
    //将初始搜索条件保存
    this.emptyObj = JSON.parse(JSON.stringify(this.searchQuery));
  },
  methods: {

    // 设置查询参数
    setSearchQuery() {
      // 深拷贝防止变量重新赋值
      this.searchQuery = JSON.parse(JSON.stringify(this.emptyObj));
      this.fetchData();
    },

    //导出明细及汇总

    async exportExcelFull(id) {
      try {
        let obj = {
          departmentId: id,
          startDate: this.searchQuery.dateArray[0] + " 00:00:00",
          endDate: this.searchQuery.dateArray[1] + " 23:59:59",
        };

        this.exportExcelLoading = true;
        let res = await download({
          url: "/meituanDepartmentApprover/excel",
          params: obj,
        });
        this.exportExcelLoading = false;
      } catch (error) {
        this.exportExcelLoading = false;
        this.$message({
          type: "error",
          message: "导出失败!",
        });
      }
    },

    //导出账单 Excel
    async exportExcel(id) {
      try {
        let obj = {
          departId: id,
          startDate: this.searchQuery.dateArray[0] + " 00:00:00",
          endDate: this.searchQuery.dateArray[1] + " 23:59:59",
        };
        this.exportExcelLoading = true;
        let res = await download({
          url: "/meituanDepartmentStatistics/exportExcel",
          params: obj,
        });
        this.exportExcelLoading = false;
      } catch (error) {
        this.exportExcelLoading = false;
        this.$message({
          type: "error",
          message: "导出失败!",
        });
      }
    },

    // 递归搜索关键字
    mapTree(value, arr) {
      let that = this;
      let newarr = [];
      arr.forEach((element) => {
        if (element.name.indexOf(value) > -1) {
          // 判断条件
          newarr.push(element);
        } else {
          if (element.children && element.children.length > 0) {
            let redata = that.mapTree(value, element.children);
            if (redata && redata.length > 0) {
              let obj = {
                ...element,
                children: redata,
              };
              newarr.push(obj);
            }
          }
        }
      });
      return newarr;
    },

    // 选择时间回调
    onChangeDate(e) {
      this.searchQuery.dateArray = e;
    },

    // table 多选回调函数
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 重置按钮事件
    onRefresh() {
      this.fetchData();
    },

    // 发送账单到审核员邮箱
    sendMail(row) {
      let mailInfo = this.mailList.filter((x) => {
        return x.departId == row.id;
      })[0];
      let { searchQuery } = this;
      let data = {
        departId: mailInfo.departId,
        recipient: mailInfo.recipient,
        recipientMail: mailInfo.recipientMail,
        startDate: searchQuery.dateArray[0] + " 00:00:00",
        endDate: searchQuery.dateArray[1] + " 23:59:59",
      };

      let res = api.commonGet({
        apiName: "/meituanDepartmentStatistics/mail",
        ...data,
      });

      this.$message({
        message: "发送成功",
        type: "success",
      });
    },
    // 删除事件
    onDel(row) {
      this.$confirm("此操作将永久删除数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delDepartment({ id: row.id })
          .then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error("删除错误！");
          });
      });
    },

    // 获取邮件列表
    async getMailList() {
      let res = await api.commonPost({ apiName: "/meituanMail" });
      if (res.code == 200) {
        this.mailList = res.data.records;
      }
    },

    //查询列表
    fetchData() {
      this.listLoading = true;
      getTreeList({
        startDate: this.searchQuery.dateArray[0] + " 00:00:00",
        endDate: this.searchQuery.dateArray[1] + " 23:59:59",
        departId: sessionStorage.getItem("departmentId"),
        userId: sessionStorage.getItem("userId"),
      }).then((response) => {
        this.list = response.data;
        this.listLoading = false;
      });
    },
    // 提交事件
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.save();
          // this.devSave();
        } else {
          return false;
        }
      });
    },
    // 保存提交到服务器
    save() {
      this.dlgLoading = true;
      saveDepartment(this.form)
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.selectDlgShow = false;
            this.dlgLoading = false;
            this.fetchData();
          } else {
            this.$message.error("保存失败");
          }
        })
        .catch(() => {
          this.dlgLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}
.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.demo-tip {
  font-size: 12px;
  color: gray;
  margin-left: 10px;
}
</style>
